// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { RootState } from 'admin/core/store';
import * as React from 'react';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import {
    Text,
    Translate,
    useTranslate,
} from '@plesk/ui-library';
import { TopBar as BaseTopBar } from 'common/components/TopBar/TopBar';
import { LicenseModel } from 'common/api/resources/Settings';
import { connect } from 'react-redux';
import { COLORS } from 'client/core/theme';
import { getLicenseName } from 'common/api/resources/ComputeResource/model';
import { HEADER } from 'admin/common/constants/tests';

export type LicenseTopBarProps =
    React.HTMLProps<HTMLElement> &
    ReturnType<typeof mapStateToProps>;

interface ITopBarProps {
    linkText: React.ReactNode;
    onLinkClick?: () => void;
    linkTo?: string;
}

export const LicenseTopBar: React.FC<LicenseTopBarProps> = ({
    solusIOLicense,
    solusVMLicense,
    meta: { isLicenseActive },
    licenseModel,
}) => {
    if (!licenseModel) {
        return null;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const translate = useTranslate();
    if (!isLicenseActive) {
        return (
            <TopBar
                linkText={<Translate content="license.topInfoPanel.activateBtn" />}
                linkTo={`${ADMIN_ROUTE_PREFIX}/license`}
            >
                <Translate content="license.topInfoPanel.description" />
            </TopBar>
        );
    }

    const isSolusVMLimitExceeded = solusVMLicense.exceeded_limits.crs_on_management_node.length > 0 ||
        solusVMLicense.exceeded_limits.cr_limit.length > 0 ||
        solusVMLicense.exceeded_limits.vs_per_cr_limit.length > 0;

    return (
        <>
            {licenseModel === LicenseModel.SOLUS_IO && solusIOLicense.cpu_cores_in_use > solusIOLicense.cpu_cores && (
                <TopBar
                    linkText={<Translate content="license.topInfoPanel.activateBtn" />}
                    linkTo={`${ADMIN_ROUTE_PREFIX}/license`}
                >
                    <Translate
                        content="license.topInfoPanel.invalidCoresAmount"
                        params={{
                            current: solusIOLicense.cpu_cores_in_use,
                            available: solusIOLicense.cpu_cores,
                        }}
                    />
                </TopBar>
            )}
            {licenseModel === LicenseModel.SOLUS_VM_V1 && isSolusVMLimitExceeded && (
                <TopBar
                    linkText={<Translate content="license.topInfoPanel.goToLicensePage" />}
                    linkTo={`${ADMIN_ROUTE_PREFIX}/license`}
                >
                    <ul>
                        {solusVMLicense.exceeded_limits.crs_on_management_node.length > 0 && (
                            <li>
                                <Translate
                                    content="license.topInfoPanel.crsOnManagementNodeVirtLimitExceeded"
                                    params={{
                                        crs: (
                                            <Text bold>
                                                {solusVMLicense.exceeded_limits.crs_on_management_node.join(', ')}
                                            </Text>
                                        ),
                                    }}
                                />
                            </li>
                        )}
                        {solusVMLicense.exceeded_limits.cr_limit.map((over_limit, index) => (
                            <li key={index}>
                                <Translate
                                    content="license.topInfoPanel.crLimitExceeded"
                                    params={{
                                        licenseType: (<Text bold>{getLicenseName(over_limit.license_type)}</Text>),
                                        count: over_limit.count,
                                        limit: over_limit.limit,
                                    }}
                                />
                            </li>
                        ))}
                        {solusVMLicense.exceeded_limits.vs_per_cr_limit.map((over_limit, index) => (
                            <li key={index}>
                                <Translate
                                    content="license.topInfoPanel.crVsLimitExceeded"
                                    params={{
                                        licenseType: (<Text bold>{getLicenseName(over_limit.license_type)}</Text>),
                                        limit: (<Text bold>{over_limit.limit}</Text>),
                                        crs: (
                                            <Text bold>
                                                {over_limit.crs.map(cr => (
                                                    translate('license.topInfoPanel.crVsLimitExceededItem', {
                                                        name: cr.name,
                                                        count: cr.count,
                                                    })
                                                )).join(', ')}
                                            </Text>
                                        ),
                                    }}
                                />
                            </li>
                        ))}
                    </ul>
                </TopBar>
            )}
        </>
    );
};

const TopBar: React.FC<React.PropsWithChildren<ITopBarProps>> = ({
    linkText,
    onLinkClick,
    linkTo,
    children,
}) => (
    <BaseTopBar
        color={COLORS.RED_0}
        backgroundColor={COLORS.RED_10}
        onLinkClick={onLinkClick}
        linkTo={linkTo}
        linkText={linkText}
        dataCy={HEADER.LICENSE_TOP_BAR}
    >
        {children}
    </BaseTopBar>
);

const mapStateToProps = (state: RootState) => ({
    meta: state.app.meta,
    solusIOLicense: state.solusIOLicense.item,
    solusVMLicense: state.solusVMLicense.item,
    licenseModel: state.settings?.license?.model,
});

export default connect(mapStateToProps)(LicenseTopBar);
